.legend-item {
    cursor: pointer;

    & > .legend-marker {
        width: 18px;
        height: 18px;
        min-width: 18px;
        min-height: 18px;
        display: inline-block;
        border-radius: 4px;
    }
}

.redesign-card {
    background-color: #182132;
    border-radius: 20px;
    padding: 15px;
}


@media (max-width: 1380px) {
    .redesign-card.col-2 {
        width: 100%;
    }
}

.mr-5 {
    margin-right: 1rem !important;
}

.ml-5 {
    margin-left: 1rem !important;
}

.mb-1 {
    margin-bottom: 1rem !important;
}

.mt-5 {
    margin-top: 3rem !important;
}


@media (min-width: 1400px) {
    .row-cols-xxl-2 > * .ml-5 {
        width: calc(50% - 1rem);
    }
}
