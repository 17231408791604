.list-header-container {
  width: calc(100% - 50px);
  & > span {
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  & > span:not(:last-child) {
    border-right: 1px solid #484848;
  }
}

.edit-title-header {
  top: 0;
  right: 0;
  width: 50px;
  height: 42px;
  padding: 8px;
  border-left: none;
}

.connectivity-indicator {
  width: 12px;
  height: 12px;
  background-color: lime;
  border-radius: 50%;
  position: absolute;
  top: -10.5px;
  right: 4.4px;
  border: 1px solid white;
}
