.dashboard {
    border-radius: 16px;
    padding: 16px;
    background-color: $primary;

    .container-fluid {
        padding: 24px 8px;
    }

    & > .title {
        display: none;
        margin: 0;
    }

    @include media-breakpoint-up(xxl) {
        padding: 16px;
        margin-top: 0;

        & > .title {
            display: initial;
        }

        .container-fluid {
            padding: 40px 0 0 0;
        }
    }

    @include media-breakpoint-down(xxl) {
        margin-top: 16px;
    }

    .chart {

    }
}

.dashboard-sidebar {
    @include media-breakpoint-up(xxl) {
        padding-top: 10px;

        .filters,
        .item-details,
        .items-container-empty,
        .items-container-heading {
            padding-left: 8px;
            padding-right: 8px;
        }
    }
}
