.layout {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
    grid-template-areas: "Header" "Content";

    .Header {
        grid-area: Header;
    }

    .Content {
        grid-area: Content;
    }

    .Sidebar {
        grid-area: Sidebar;
        display: none !important;
        padding: 48px 12px 0 20px;
    }

    @include media-breakpoint-up(sm) {
        &.adaptive {
            grid-template-columns: min-content 1fr;
            grid-template-rows: 1fr;
            grid-template-areas: "Sidebar Content";

            .Header {
                display: none !important;
            }

            .Sidebar {
                display: flex !important;
            }
        }
    }
}
