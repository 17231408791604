.input, .input:disabled {
    color: #FFF;
    height: 35px;
    font-size: 16px;
    border-color: #FFF;
    border-width: 0 0 2px 0;
    background-color: transparent;
    padding-left: 12px;
    line-height: 1;
    height: auto;
    border-radius: 0;

    &.borderless {
        border-width: 0;
    }
}

.input:focus {
    outline: none;
}

.input:focus ~ label,
.input:not(:placeholder-shown) ~ label
{
    top: -10px;
    left: 12px;
    opacity: 1;
    font-size: 10px;
}

.input ~ label {
    left: 12px;
    color: #FFF;
    font-size: 16px;
    position: absolute;
    pointer-events: none;
    line-height: 1;
    transition: 0.2s ease all;
}

.labeled-input {
    position: relative;

    &.fluid {
        width: 100%;

        & > .input {
            width: 100%;
        }
    }
}

.form {
    display: flex;
    row-gap: 24px;

    &.compact {
        width: 100%;
        max-width: 290px;
    }
}

.dropdown button.dropdown-toggle {
    background-color: transparent !important;
    border: 0;
    padding: 0;
    border-bottom: 2px solid #FFFFFF !important;
    border-radius: 0;
    width: "100%";
}

.input-group-button {
    border-radius: 0;
    border-width: 0 0 2px 0;
    background-color: transparent;
    border-color: transparent transparent #FFFFFF transparent;

    &:hover, &:focus, &:active, &:active:hover {
        outline: 0;
        box-shadow: none;
        background-color: rgba(255, 255, 255, 0.1);
        border-color: transparent transparent #FFFFFF transparent;
    }
}

.search-input {
    border-bottom: 2px solid #FFF;

    & .input {
        padding-left: 10px;

        &:focus ~ label, &:not(:placeholder-shown) ~ label {
            left: 10px;
        }
    }
}

input[type="checkbox"] {
    &, &:checked {
        background-color: transparent;
        border-color: #FFF;
    }

    &, & ~ label {
        cursor: pointer;
    }
}

textarea.form-control {
    background-color: transparent;
    border: 2px solid #FFF;
    margin-top: 2px;
    color: #FFF;

    &:focus {
        background-color: transparent;
        color: #FFF;
    }
}