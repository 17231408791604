.custom-dropdown {
    .dropdown-menu-dark .dropdown-item.active, 
    .dropdown-menu-dark .dropdown-item:active {
        background-color: #04233f;
    }

    .dropdown-item:hover {
        background-color: #263440;
    }

    &.fluid {
        .dropdown-toggle > span {
            width: 100%;
            padding-left: 12px;
            display: inline-block;
        }

        .dropdown-toggle::after {
            margin-left: -0.85em;
        }
    }

    .dropdown-menu-dark {
        padding: 0;
        background-color: #00101F;
        border: 1px solid #00101F;
        border-color: #29363e;
    }

    .btn-check:active + .btn-outline-dark, 
    .btn-outline-dark.active, 
    .btn-outline-dark:active {
        background-color: #04233f;
        border-color: #04233f;
    }

    .btn-outline-dark:hover:not(.active) {
        background-color: #263440; 
    }

    .dropdown-toggle:focus {
        box-shadow: none !important;
    }

    &.button {
        button {
            border-bottom: none !important;
            font-size: 14px;
            padding: 0px 6px;
            line-height: 1;
            text-align: center;
        }

        .dropdown-toggle::after {
            margin-left: 5px;
        }

        .dropdown-menu.show {
            transform: translate(0px, 30px) !important;
        }
    }

}