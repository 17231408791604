.header-nav {
    font-size: 24px;
    padding: 16px 16px 0 16px !important;

    & div {
        column-gap: 20px;
    }

    a {
        text-decoration: none;
    }

    h2.brand-label {
        margin: 0;
        font-size: 24px;
        color: #FFFFFF;
    }

    img.brand-logo {
        width: 24px;
    
        &.desktop {
            display: none !important;
        }

        &.mobile {
            display: block !important;
        }
    }
}

@include media-breakpoint-up(sm) {
    .header-nav {
        padding: 64px 64px 0 64px !important;

        & > div {
            column-gap: 32px;
        }

        img.brand-logo {
            width: 96px;

            &.desktop {
                display: block !important;
            }

            &.mobile {
                display: none !important;
            }
        }

        h2.brand-label {
            font-size: 32px;
        }
    }
}