.event-toggle-button {
    min-width: 120px;
  }
  
  .event-options {
    .event-trigger-button {
      min-width: 120px;
      line-height: 1;
      font-size: 14px;
      padding: 6px 24px;
      border-radius: 24px;
    }
  }
  
  .custom-trigger-container {
    margin-left: calc(120px + 14px);
    display: flex;
    align-items: center;
  
    .custom-trigger-input {
      width: 200px;
      line-height: 1;
      font-size: 14px;
      padding: 6px 24px;
      border-radius: 24px;
    }
  
    .send-trigger-button {
      min-width: 120px;
      line-height: 1;
      font-size: 14px;
      padding: 6px 24px;
      border-radius: 24px;
    }
  }
  