span.icon {
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @mixin size($icon-size) {
        width: $icon-size;
        height: $icon-size;
        min-width: $icon-size;
        min-height: $icon-size;
    }

    &.profile { background-image: url("../../../../public/images/icons/ic_profile.svg"); }
    &.invite { background-image: url("../../../../public/images/icons/ic_invite.svg"); }
    &.bars-horizontal { background-image: url("../../../../public/images/icons/ic_bars-horizontal.svg"); }
    &.logout { background-image: url("../../../../public/images/icons/ic_logout.svg"); }
    &.download { background-image: url("../../../../public/images//icons/ic_download.svg"); }
    &.pen { background-image: url("../../../../public/images/icons/ic_pen.svg"); }
    &.times { background-image: url("../../../../public/images/icons/ic_times.svg"); }
    &.arrow-left { background-image: url("../../../../public/images/icons/ic_arrow-left.svg"); }
    &.sliders { background-image: url("../../../../public/images/icons/ic_sliders.svg"); }
    &.devices { background-image: url("../../../../public/images/icons/ic_devices.svg"); }
    &.add { background-image: url("../../../../public/images/icons/ic_add.svg"); }
    &.chevron-left { background-image: url("../../../../public/images/icons/ic_chevron-left.svg"); }
    &.chevron-right { background-image: url("../../../../public/images/icons/ic_chevron-right.svg"); }
    &.exclamation { background-image: url("../../../../public/images/icons/ic_exclamation.svg"); }

    &.play { background-image: url("../../../../public/images/icons/ic_play.svg"); }
    &.stop { background-image: url("../../../../public/images/icons/ic_stop.svg"); }
    &.pause { background-image: url("../../../../public/images/icons/ic_pause.svg"); }

    &.tiny { @include size(0.85em); }
    &.small { @include size(1em); }
    &.medium { @include size(1.5em) }
    &.large { @include size(3em) }
}