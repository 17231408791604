.task-controls {
    width: 30%;
    border: 1px solid white;
    padding: 5px 15px;
    float: right;
    border-radius: 15px;
}

.control-container {
    flex: 1;
    text-align: center;
    padding: 5px 0 0 0;
}

.control-button.disabled {
    opacity: 0.2;
    pointer-events: none;
}