html {
  overflow: auto;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background: linear-gradient(180deg, $primary 0%, #023c5d 100%);

  & > .container {
    height: 100%;
    overflow: auto;
    position: relative;
  }

  &::before {
    content: " ";
    filter: blur(100px);
    position: absolute;
    width: 105.1%;
    height: 183.5%;
    left: -57.3%;
    bottom: -107.3%;
    background: radial-gradient(61.5% 57.88% at 51.46% 42.33%, rgba(25, 221, 251, 0.3) 0%, rgba(9, 28, 32, 0.3) 44.27%);
  }

  &::after {
    content: " ";
    filter: blur(100px);
    position: absolute;
    width: 21.5%;
    height: 37.3%;
    right: -9.9%;
    top: -13.5%;
    background: radial-gradient(
      153.73% 135.63% at 67.12% 55.64%,
      rgba(255, 107, 0, 0.3) 0%,
      rgba(9, 28, 32, 0.3) 95.67%
    );
  }
}

input:autofill {
  background: transparent;
}

@include media-breakpoint-up(sm) {
  .modal-dialog {
    max-width: 450px;
  }
}

.modal-header {
  border: 0;
}

.modal-content {
  padding: 24px 48px;
  border-radius: 16px;
  background-color: $primary;
}

.fade {
  background-color: rgba(0, 0, 0, 0.7);
}

.main-container {
  position: relative;
  z-index: 100;
  min-height: 100vh;
  height: 100vh;
  overflow: auto;
}

.bg-black * {
  color: $white;
}

.item {
  border: 0;
  padding: 12px 24px;
  border-radius: 8px;
  background-color: $card-bg !important;

  &.active {
    font-weight: bold;
  }
}

.item-card {
  box-shadow: 2px 2px 7px 1px #0d0d0d;
}

.list-container {
  padding: 25px 10% 0;
}

.bordered-item {
  border-bottom: 1px solid $dark-gray;
}

.modal-right-buttons {
  display: inline;
  float: right;
}

button.btn.btn-secondary {
  background-color: $gray-button !important;
}

select {
  border: 2px solid black;
  border-radius: 5px;
  padding: 3px 2px;
  color: white;
}

button.btn-secondary {
  border: none;
}

.logo-container {
  height: 55px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.sort-container select,
.sort-container select option {
  background-color: $main-bg !important;
}

#chartdiv {
  height: 500px;
}

.breadcrumb-item {
  & a {
    color: $white;
    text-decoration: none;
    &:hover {
      color: $gray-input;
    }
  }
}

#legendDiv {
  background-color: rgb(0, 0, 0);
  height: calc(-200px + 100vh);
  position: absolute;
  left: 0;
  width: 275px;
  top: 120px;
  z-index: 999;
}

.streaming-status {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  border-radius: 50%;
  display: block;
  transition: background-color 0.3s ease-in-out;

  &.active { background-color: $status-active; }
  &.paused { background-color: $status-paused; }
  &.inactive { background-color: $status-inactive; }
}

// Text.
.text {
  &-initial { color: $text-initial; }
  &-alternative { color: $text-alternative; }
  &-huge { font-size: 64px; }
  &-large { font-size: 32px; }
  &-larger { font-size: 36px; }
  &-medium { font-size: 24px; }
  &-small { font-size: 16px; }
  &-strong { font-weight: bold; }
  &-link {
    line-height: 1.2;
    margin-bottom: 8px;
    text-decoration: none;
    color: $text-alternative;
  }
}

a.text-link {
  &:hover {
    filter: brightness(0.85);
    color: $text-alternative !important;
  }
}

@include media-breakpoint-down(sm) {
  .text {
    &-huge { font-size: calc(3.275rem + .3vw) }
    &-large { font-size: 24px; }
    &-larger { font-size: 28px; }
    &-medium { font-size: 20px; }
    &-small { font-size: 14px; }
  }
}

.errors-list {
  margin-top: 8px;

  &:empty {
    display: none;
  }
}

.error {
  color: #FF7F2A;
  display: block;
  font-size: 14px;

  &:empty {
    display: none;
  }
}

// Breakpoints.
.show-on-mobile { display: none !important; }
// .show-on-desktop { display: block !important; }

@include media-breakpoint-down(sm) {
  .show-on-mobile { display: block !important; }
  .show-on-desktop { display: none !important; }
}

.hover-source {
  .hover-target {
    transition: opacity 0.3s ease-in-out;
  }

  &:not(:hover) {
    .hover-target {
      opacity: 0 !important;
    }
  }
}

// Formatting.
.pt-32 {
  padding-top: 32px;
}

.py-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.mt-52 {
  margin-top: 52px;
}

.redesign-card .text-larger {
  font-size: 20px;
  text-align: left !important;
  font-weight: bold;
}
