.table {
    & > :not(:first-child) {
        border-top: 0;
    }

    &.sortable {
        th {
            cursor: pointer;
            user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
        }
    }

    td {
        border-bottom: 0;
    }
}