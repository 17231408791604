#profile-popover {
  margin-top: -15px;
  background-color: $black;
  & > .popover-arrow {
    display: none;
  }
  & .profile-popover-link-container {
    padding: 7px 30px 7px 15px;
    &:hover {
      background-color: #3c3c3c;
    }
    &:not(:last-child) {
      border-bottom: 1px solid $dark-gray;
    }
  }
  & a {
    text-decoration: none;
    color: $white;
  }
  & > .popover-body {
    padding: 0;
  }
}

.profile-botton {
  &:focus {
    box-shadow: none !important;
  }
}
