.welcome-guide {
    position: absolute;
    color: white;
    top: 50px;
    left: 120px;
    background-color: $primary; 
    padding: 1rem;
    border: solid 1px white;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    max-width: 400px;
  }