.carousel-controls {
    display: flex;
    justify-content: space-between;
    width: 100%;

    & .carousel-controls-item {
        width: 24px;
        height: 24px;
        cursor: pointer;
        border-radius: 50%;
        border: 2px solid #FFF;
        background-color: transparent;
        transition: background-color 0.2s ease-in-out;

        &.active {
            background-color: #FFF;
        }
    }
}