.flex-container {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

.w-unset {
  width: unset !important;
}

@for $i from 14 through 30 {
  .font-#{$i} {
    font-size: $i + px;
  }
}

body .mt-0-f {
  // force mt-0
  margin-top: -0px !important;
}

.t-0 {
  top: 0;
}

.b-0 {
  bottom: 0;
}

.l-0 {
  left: 0;
}

.r-0 {
  right: 0;
}

body .mt-25 {
  margin-top: 0.75rem !important;
}

body .me-25 {
  margin-right: 0.75rem !important;
}

.h-100-w {
  height: 100vh;
}

.h-50-px {
  height: 50px;
}

.round {
  border-radius: 50%;
}

.no-focus:focus {
  outline: none !important;
  box-shadow: none !important;
}

.rounded-50 {
  border-radius: 50% !important;
}

.rounded-10 {
  border-radius: 10px !important;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.rotate-180 {
  transform: rotate(180deg);
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

button.custom-primary {
  background-color: #5267f4;
}

button.variant-custom-primary {
  border: 1px solid #5267f4 !important;
}

button.custom-danger {
  border: #dc3545 1px solid !important;
}

.vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-top {
  vertical-align: top;
}

.hover-pointer:hover {
  cursor: pointer;
}

.text-hover-black:hover {
  color: $black !important;
}

.flex-direction-column {
  flex-direction: column !important;
}

.flex-direction-row {
  flex-direction: row !important;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.justify-content-space-between {
  justify-content: space-between;
}

.equal-width-flex {
  flex-grow: 1;
  flex-basis: 0;
}