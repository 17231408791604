$main-bg: #25262b;
$primary: #00101F;
$black: #000;
$white: #fff;
$card-bg: rgba(0, 16, 31, 0.4);
$card-bg-active: #012E4A;
$dark-gray: rgb(61, 61, 61);
$dark-white: rgb(186, 186, 186);
$gray-button: #3a4147;
$gray-input: rgb(155, 161, 161);
$status-active: #43F616;
$status-inactive: #BDBDBD;
$status-paused: #F6D916;
$red: rgb(222, 71, 57);

$text-initial: #FFFFFF;
$text-alternative: #49E5FB;

$btn-primary: #E04D04;
$btn-primary-border: #E04D04;
$btn-primary-hover: #FF9752;
$btn-primary-text: #FFFFFF;
$btn-alternative: #49E5FB;
$btn-alternative-border: #2D9CDB;
$btn-alternative-hover: #85fdff;
$btn-alternative-text: #023C5D;