.button {
    line-height: 1;
    font-size: 14px;
    text-align: right;
    padding: 6px 24px;
    border-radius: 24px;
    color: $btn-primary-text;
    background-color: $btn-primary;
    border: 1px solid $btn-primary-border;
    transition: 
        background-color 0.3s ease-in-out,
        border-color 0.3s ease-in-out;

    &:not(:disabled):hover,
    &:not(:disabled):focus,
    &:active {
        background-color: $btn-primary-hover;
        border-color: $btn-primary-hover;
    }

    &.alternative {
        color: $btn-alternative-text;
        background-color: $btn-alternative;
        border-color: $btn-alternative-border;

        &:not(:disabled):hover,
        &:not(:disabled):focus,
        &:active {
            border-color: $btn-alternative-border;
            background-color: $btn-alternative-hover;
        }
    }

    &.text {
        border: 0;
        padding: 0;
        color: $btn-alternative;
        background-color: transparent;

        &:not(:disabled):focus,
        &:not(:disabled):hover,
        &:active {
            filter: brightness(110%);
            background-color: transparent;
        }

        &.active {
            text-decoration: underline;
        }
    }

    &:disabled {
        opacity: 0.55;
        cursor: not-allowed;
    }

    &.wide {
        width: 100%;
        max-width: 200px;
    }

    &.link {
        padding: 0;
        color: $text-initial;
        background-color: transparent;
        border-color: transparent;
        text-decoration: underline;

        &:hover {
            filter: brightness(0.75);
            background-color: transparent;
            border-color: transparent;
        }
    }

    &.icon {
        border: 0;
        padding: 0;
        color: $text-initial;
        height: min-content;
        background-color: transparent;

        &:hover {
            filter: brightness(0.75);
            background-color: transparent;
        }

        &:focus {
            background-color: transparent;
        }
    }
}
