a.link {
    color: #FFFFFF;
    text-decoration-color: #FFFFFF;

    &:hover {
        color: #FFFFFF;
    }
}

.nav-link {
    cursor: pointer;
    padding: 0 !important;
}

.text-link {
    display: block;
}

button:hover > a.text-hover-black {
    color: $black !important;
}